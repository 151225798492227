import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/nftx-developers.github.io/nftx-developers.github.io/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`In the world of Gatsby themes,
`}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.org/blog/2019-04-29-component-shadowing/"
        }}>{`component shadowing`}</a>{`
is an extremely powerful way for developers to provide their `}<em parentName="p">{`own`}</em>{` components
for the theme to use over the defaults. You can shadow any file that is
processed by webpack. This includes sass files, mdx files, and the react
components themselves.`}</p>
    </PageDescription>
    <h2>{`Caveats`}</h2>
    <p>{`If you completely shadow a file you won’t get future updates to that individual
file. These are some golden rules to make sure you stay as close to the theme as
possible and not forgo future updates.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Open a
`}<a parentName="p" {...{
            "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/issues/new"
          }}>{`quick issue`}</a>{`
in the gatsby-theme-carbon repo to make sure we’re not working on your
change.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Shadow as few files as you can to achieve your goal. If you can shadow just a
single file, that’s ideal.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If you do end up shadowing a component, please tell us! We might use it to
inform future development.`}</p>
      </li>
    </ol>
    <h2>{`Shadowing basics`}</h2>
    <p>{`In order to shadow a component in the theme, all you need to do is place a
directory in the `}<inlineCode parentName="p">{`src/gatsby-theme-carbon`}</inlineCode>{` in your project. The directory should
have the same name as the file you’re shadowing.`}</p>
    <p>{`In order to place your own title in the Header component:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a directory the same name as the component you wish to shadow.
Everything after `}<inlineCode parentName="p">{`src/gatsby-theme-carbon/`}</inlineCode>{` refers to the
`}<a parentName="p" {...{
            "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src"
          }}>{`src directory of gatsby-theme-carbon`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a file inside of the directory that matches the component you want to
shadow. For example: `}<inlineCode parentName="p">{`src/gatsby-theme-carbon/components/Header/index.js`}</inlineCode>{`.
`}<em parentName="p">{`If shadowing Footer or Header the file must be named `}<strong parentName="em"><inlineCode parentName="strong">{`index.js`}</inlineCode></strong></em></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Import the component you wish to shadow by providing the full url pointing at
the component within the theme`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your component will `}<strong parentName="p">{`receive the same props`}</strong>{` as the one you’re shadowing.
You’ll can log those props to see if you’ll need any of them`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Regardless, you should `}<em parentName="p">{`ALWAYS`}</em>{` spread the extra props into the original
component, this allows the core component to function as needed. Even if it
doesn’t receive any props now, it might in the future.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Provide your new, custom component as the default export`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import Header from 'gatsby-theme-carbon/src/components/Header';

const CustomHeader = (props) => (
  <Header {...props}>
    <span>Gatsby theme</span>&nbsp;Carbon
  </Header>
);

export default CustomHeader;
`}</code></pre>
    <h2>{`Components you’ll need to shadow`}</h2>
    <p>{`We’ve already provided pre-shadowed, dummy components however these are the ones
you’ll definitely need to shadow.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Path`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ResourceLinks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/main/packages/example/src/gatsby-theme-carbon/components/LeftNav/ResourceLinks.js"
            }}><inlineCode parentName="a">{`src/gatsby-theme-carbon/components/LeftNav/ResourceLinks.js`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The bottom links on the SideNav, pass `}<inlineCode parentName="td">{`shouldOpenNewTabs`}</inlineCode>{` to open links externally`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Footer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/main/packages/example/src/gatsby-theme-carbon/components/Footer/index.js"
            }}><inlineCode parentName="a">{`src/gatsby-theme-carbon/components/Footer/index.js`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The links and content at the bottom of each page`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/main/packages/example/src/gatsby-theme-carbon/components/Header/index.js"
            }}><inlineCode parentName="a">{`src/gatsby-theme-carbon/components/Header/index.js`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text in the top left corner of the UI Shell`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      